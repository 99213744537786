:root {
  --bg_primary:rgb(200, 217, 190);
  --bg_primary_transparent:rgb(200, 217, 190, 0.9);
  --bg_background:rgb(238, 237, 235);
  --accent1: rgb(71, 119, 68);
  --accent2: rgb(93, 163, 163);
  --bg_hover: "";
  
  --font_headers: 'Limelight', cursive;
  --font_body: 'Montserrat', sans-serif;
  --f_header: rgb(57, 96, 85);
  --f_body: black;
  --f_link: rgb(96, 184, 133);
  --f_hover: rgb(81, 161, 190);
  --star_fill: rgb(242, 226, 45);
}

* {
  box-sizing: border-box;
}

@media only screen and (min-width: 600px) {
  .about {
    width: 100%;
    float: center;
  }

  .menu {
    width: 100%;
    float: center;
  }
}

@media only screen and (min-width: 768px) {
  .about {
    float: right;
    width: 40%;
  }

  .menu {
    float: left;
    width: 60%;
  }
}

a {
  text-decoration: none;
  color: rgb(71, 119, 68);
  color: var(--accent1);
}

a:hover {
  color: rgb(93, 163, 163);
  color: var(--accent2);
}

body {
  background-color: rgb(200, 217, 190);
  background-color: var(--bg_primary);
  background-image: url(/static/media/background.4271f8b0.png);
  background-position: center;
  background-size: 100%;
}

h1 {
  color: rgb(57, 96, 85);
  color: var(--f_header);
  font-size: 350%;
  font-weight: bolder;
  font-family: 'Limelight', cursive;
  font-family: var(--font_headers);
}

h1:hover {
  color: rgb(71, 119, 68);
  color: var(--accent1)
}

h3 {
  font-size: x-large;
  font-weight: bolder;
  font-family: 'Limelight', cursive;
  font-family: var(--font_headers);
}

h5 {
  font-size: medium;
  font-weight: bolder;
  font-family: 'Limelight', cursive;
  font-family: var(--font_headers);
}

ul {
  list-style: none;
}
  
.about {
  background-color: rgb(93, 163, 163);
  background-color: var(--accent2);
  color: black;
  color: var(--f_body);
  border-style: solid;
  border-radius: 10px;
  border-color: rgb(200, 217, 190);
  border-color: var(--bg_primary);
  padding: 10px;
  text-align: center;
}

.about p {
  padding: 10px;
}

.body {
  position: relative;
  width: 100%;
  padding-top: 5px;
}

.container {
  font-family: 'Montserrat', sans-serif;
  font-family: var(--font_body);
  font-size: small;
  position: relative;
  display: block;
  overflow: hidden;
  margin: auto;
  padding: 5px 5px 45px;
  min-height: 100vh;
  width: 100%;
}

.description {
  list-style: circle outside;
}

.entries {
  background-color: rgb(238, 237, 235);
  background-color: var(--bg_background);
  padding: 10px;
  overflow: auto;
  max-height: 70vh;
  width: 100%;
}

.footer {
  bottom: 0;
  color: rgb(57, 96, 85);
  color: var(--f_header);
  background-color: rgb(200, 217, 190, 0.9);
  background-color: var(--bg_primary_transparent);
  display: block;
  font-size: smaller;
  position: absolute;
  text-align: center;
  width: 100%;
}

.header {
  color: rgb(57, 96, 85);
  color: var(--f_header);
  background-color: rgb(200, 217, 190, 0.9);
  background-color: var(--bg_primary_transparent);
  display: block;
  text-align: center;
  width: 100%;
  padding: 5px;
}

.menu {
  color: black;
  color: var(--f_body);
  text-align: left;
}

.navBar {
  border: 5px double rgb(93, 163, 163);
  border: 5px double var(--accent2);
  padding: 10px;
}

.nav {
  display: block;
}

.nav li {
  color: rgb(71, 119, 68);
  color: var(--accent1);
  display: inline-block;
  padding: 0 20px;
  text-align: center;
}

.sideNote {
  float: right;
  font-size: x-small;
}

.section {
  background-color: rgb(238, 237, 235);
  background-color: var(--bg_background);
  border-style: solid;
  border-radius: 10px;
  border-color: rgb(200, 217, 190);
  border-color: var(--bg_primary);
  padding: 10px;
}

.slideshow {
  width: 100%;
  height: 70vh;
  white-space: nowrap;
}

.slideshow img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.stars {
  float: right;
  margin-left: 10px;
  color: rgb(242, 226, 45);
  color: var(--star_fill);
}

::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgb(71, 119, 68);
  background: var(--accent1);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(93, 163, 163);
  background: var(--accent2);
}
